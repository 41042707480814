<script>
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { ContentLoader } from 'vue-content-loader';
import axios from 'axios';
import Swal from "sweetalert2";

const extensions = [
    'xls', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb',
    'xlam', 'docx', 'docm', 'dotx', 'dotm', 'pptx',
    'pptm', 'potx', 'potm', 'ppam', 'ppsx', 'ppsm',
    'sldx', 'sldm', 'thmx', 'pdf', 'png', 'jpg', 'txt'
];

const values = [
    'ri-file-excel-fill', 'ri-file-excel-fill', 'ri-file-excel-fill', 'ri-file-excel-fill', 'ri-file-excel-fill', 'ri-file-excel-fill',
    'ri-file-excel-fill', 'ri-file-word-2-fill', 'ri-file-word-2-fill', 'ri-file-word-fill', 'ri-file-word-fill', 'ri-file-ppt-fill',
    'ri-file-ppt-fill', 'ri-file-ppt-fill', 'ri-file-ppt-fill', 'ri-file-ppt-fill', 'ri-file-ppt-fill', 'ri-file-ppt-fill',
    'ri-file-ppt-fill', 'ri-file-ppt-fill', 'ri-file-ppt-fill', 'ri-file-pdf-fill', 'ri-gallery-fill', 'ri-gallery-fill', 'ri-file-text-fill'
];

const icons = values.reduce((acc, value, i) => {
  acc[extensions[i]] = value;
  return acc
}, {});

const demoContacts = [
  {
    COMPANY_ID: null,
    ID: "19179",
    LAST_NAME:"Contact #19179",
    NAME: "Contact #19179",
    SOURCE_ID: "CALL",
    TYPE_ID: "CLIENT",
    EMAIL: [
      {
        ID: "152190",
        TYPE_ID: "EMAIL",
        VALUE: "soportesistemas4@maqro.com.mx",
        VALUE_TYPE: "WORK"
      }
    ]
  },
  {
    COMPANY_ID: "16686",
    ID: "32682",
    LAST_NAME: "Medina",
    NAME: "soportesistemas4m@gmail.com",
    SOURCE_ID: "EMAIL",
    TYPE_ID: "CLIENT",
    EMAIL: []
  }
];


export default {
  name: "client",
  data: () => ({
    title: "ENVIAR INFORMACIÓN",
    items: [
      {
        text: "Enviar información",
        href: "/",
      },
      {
        text: "Cliente",
        active: true,
      },
    ],
    loadInformationList: [
      {
        id: 1,
        name: 'Contacto'
      },
      {
        id: 2,
        name: 'Compañía'
      }
    ],
    loadBrandList: [
      {
        id: 1,
        name: 'Heli'
      },
      {
        id: 2,
        name: 'Sennebogen'
      },
      {
        id: 3,
        name: 'Xuzhou'
      },
      {
        id: 4,
        name: 'Dieci'
      },
      {
        id: 5,
        name: 'Terex'
      },
      {
        id: 6,
        name: 'NPC'
      },
      {
        id: 7,
        name: 'John Deere'
      },
      {
        id: 8,
        name: 'Compañía'
      }
    ],
    searchListing: [],
    selectedSearch: null,
    selectedFilter: null,
    contactId: null,
    contactName: null,
    contactEmail: null,
    companyName: null,
    companySelected: null,

    selectedContactByCompany: null,
    contactsByCompany: [],
    navigations: [
      {
        NAME: "/",
        ID: null,
        INDEX: 0,
      },
    ],
    folders: [],
    files: [],
    page: 1,
    perPage: 10,
    pages: [],
    isLoading: false,
    auth: null,
  }),
  components: {
    Layout, PageHeader, Multiselect, ContentLoader
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.folders);
    },
    resultQuery() {
      return this.displayedPosts;
    },
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
  created() {
    this.setPages();
  },
  async mounted() {
    await this.fetchUserAuth();
    this.selectedFilter = this.loadInformationList[0];
    await this.fetchContacts();
    await this.fetchStorageList();
    this.setPages();

    console.info('URL SEND EMAIL:', process.env.VUE_APP_API_SMSBOX);
    console.info('ENV:', process.env);
  },
  methods: {
    async fetchUserAuth() {
      console.log('fetchUserAuth ---- 1');
      if (typeof window.BX24 === 'object' && window.BX24 !== null) {
        await this.loadUserAuth();
      } else {
        await this.loadDemoUserAuth();
      }
    },
    async loadUserAuth() {
      console.log('fetchUserAuth ---- 2');
      return new Promise((resolve) => {
        window.BX24.callMethod('user.current', {}, (response) => {
          console.info("User Current:", response.data());
          this.auth = response.data();
          resolve(this.auth);
        });
      });
    },
    async fetchContacts() {
      if (typeof window.BX24 === 'object' && window.BX24 !== null) {
        this.loadContacts();
      } else {
        this.loadDemoContacts();
      }
    },
    fetchCompanies() {
      if (typeof window.BX24 === 'object' && window.BX24 !== null) {
        this.loadCompanies();
      } else {
        this.loadDemoCompanies();
      }
    },

    async activityAdd() {
      if (typeof window.BX24 === 'object' && window.BX24 !== null) {
        const d = new Date();

        const paddatepart = function(part) {
          return part >= 10 ? part.toString() : '0' + part.toString();
        }

        d.setDate(d.getDate() + 7);
        d.setSeconds(0);

        const dateStr = d.getFullYear() + '-' + paddatepart(1 + d.getMonth()) + '-' + paddatepart(d.getDate()) + 'T' + paddatepart(d.getHours()) + ':'
            + paddatepart(d.getMinutes()) + ':' + paddatepart(d.getSeconds()) + '+00:00';

        if (!this.contactId) {
          const contactId = await this.saveContact();
          await this.getContact(contactId);
        }

        console.info('VALUE contactId:', this.contactId)

        if(this.contactId) {
          console.log('Enter register activity...');

          return new Promise((resolve) => {
            window.BX24.callMethod(
                "crm.activity.add",
                {
                  fields:
                      {
                        "OWNER_TYPE_ID": 2,
                        "TYPE_ID": 4,
                        "COMMUNICATIONS": [{VALUE: this.contactEmail, ENTITY_ID: this.contactId, ENTITY_TYPE_ID: 3}],
                        "SUBJECT": `Enviamos documentos por email: ${this.contactName}`,
                        "START_TIME": dateStr,
                        "END_TIME": dateStr,
                        "COMPLETED": "Y",
                        "PRIORITY": 3,
                        "RESPONSIBLE_ID": this.auth.ID,
                        "DESCRIPTION": `Enviamos documentos por email: ${this.contactName}`,
                        "DESCRIPTION_TYPE": 3,
                        "DIRECTION": 2
                      }
                }, (result) => {
                  if (result.error())
                    console.error(result.error());
                  else
                    console.info("Add new activity with ID is created " + result.data());
                  resolve(result.data());
                }
            );
          });
        }
      }
    },

    async saveContact() {
      const email = this.contactEmail

      return new Promise((resolve) => {
        window.BX24.callMethod(
            "crm.contact.add",
            {
              fields:
                  {
                    "NAME": this.contactName,
                    "OPENED": "Y",
                    "ASSIGNED_BY_ID": 1,
                    "TYPE_ID": "CLIENT",
                    "SOURCE_ID": "SELF",
                    "EMAIL": [{"VALUE": email, "VALUE_TYPE": "WORK"}]
                  },
              params: {"REGISTER_SONET_EVENT": "Y"}
            }, (response) => {
              if (response.error()) {
                console.error(response.error());
              } else {
                console.info("Created a new contact; ID=" + response.data());
                resolve(response.data());
              }
            }
        );
      });
    },

    async getContact(contactId) {
      if (contactId) {
        return new Promise((resolve) => {
          window.BX24.callMethod(
              "crm.contact.get",
              {id: contactId},
              (response) => {
                if (response.error()) {
                  console.error(response.error());
                } else {
                  console.dir(response.data());
                  this.setInfoContact(response.data());
                  console.info('INFO CONTACT CREATED:', response.data());
                  resolve(response.data())
                }
              }
          );
        });
      }
    },

    loadContacts() {
      window.BX24.callMethod(
          "crm.contact.list",
          {
            order: {"DATE_CREATE": "ASC"},
            filter: {"TYPE_ID": "CLIENT"},
            select: ["ID", "NAME", "LAST_NAME", "TYPE_ID", "SOURCE_ID", "EMAIL"]
          },  (response) => {
            if (response.error()) {
              console.error(response.error());
            } else {
              setTimeout(() => {
                this.searchListing = response.data();
              }, 1000)
            }
          }
      );
    },

    loadCompanies() {
      setTimeout(() => {
        window.BX24.callMethod(
            "crm.company.list",
            {
              order: { "DATE_CREATE": "ASC" },
              filter: { "INDUSTRY": "MANUFACTURING", "COMPANY_TYPE": "CUSTOMER" },
              select: [ "ID", "TITLE", "CURRENCY_ID", "REVENUE" ]
            }, (response) => {
              if (response.error()) {
                console.error(response.error());
              } else {
                this.searchListing = response.data().map(company => ({...company, NAME: company.TITLE}));
              }
            }
        );
      }, 1000);
    },

    fetchCompany() {
      if (this.selectedFilter.id === 1 && this.selectedSearch.COMPANY_ID !== null) {
        if (typeof window.BX24 === 'object' && window.BX24 !== null) {
          this.loadCompany();
        } else {
          this.loadDemoCompany();
        }
      }
    },

    fetchContactsByCompany() {
      if (typeof window.BX24 === 'object' && window.BX24 !== null) {
        this.loadContactsByCompany();
      } else {
        this.loadDemoContactsByCompany();
      }
    },

    loadCompany() {
      setTimeout(() => {
        window.BX24.callMethod(
            "crm.company.get",
            {id: this.selectedSearch.ID},
            (response) => {
              if (response.error()) {
                console.error(response.error());
              } else {
                this.companySelected = response.data();
                this.companyName = this.companySelected.TITLE;
              }
            }
        );
      }, 1000)
    },
    loadContactsByCompany() {
      window.BX24.callMethod(
          "crm.company.contact.items.get",
          {
            id: this.searchListing.ID
          },
          (response) => {
            if(response.error()) {
              console.error(response.error());
            } else {
              this.fetchContactsById(response.data());
            }
          }
      );
    },

    fetchContactsById(contactsId) {
      contactsId.foreach(contact => {
        window.BX24.callMethod(
            "crm.contact.get",
            { id: contact.CONTACT_ID },
            (response) => {
              if(response.error())
                console.error(response.error());
              else
                this.contactsByCompany.push(response.data());
            }
        );
      });
    },
    onChangeFilter(selected) {
      this.resetInput();

      switch (selected.id){
        case 1:
          this.fetchContacts();
          break;
        case 2:
          this.fetchCompanies();
          break
        default:
          this.fetchContacts();
      }
    },
    setInfoContact(contact) {
      this.contactId = contact.ID
      this.contactName = contact.LAST_NAME ? `${contact.NAME} ${contact.LAST_NAME}` : contact.NAME;
      this.contactEmail = contact.EMAIL[0]?.VALUE ?? null;
    },
    onChangeSearch(selected) {
      if (this.selectedFilter.id === 1) {

        this.setInfoContact(selected)

        this.fetchCompany();
      } else {
        this.companySelected = selected;
        this.companyName = this.companySelected?.TITLE;
        this.fetchContactsByCompany();
      }
    },

    onChangeContactByCompany(selected) {
      this.setInfoContact(selected)
    },

    async fetchStorageList() {
      if (typeof window.BX24 === 'object' && window.BX24 !== null) {
        this.loadStorage();
      } else  {
        this.loadDemoStorage();
      }
    },

    fetchChildrenStorage(storage) {
      if (typeof window.BX24 === 'object' && window.BX24 !== null) {
        this.loadChildrenStorage(storage)
      } else {
        this.loadDemoChildrenStorage(storage)
      }
    },


    fetchFolders(folder) {
      if (typeof window.BX24 === 'object' && window.BX24 !== null) {
        this.loadFolders(folder)
      } else {
        this.loadDemoFolders(folder)
      }
    },

    loadStorage() {
      this.isLoading = true

      console.log(
          '===$this.auth===',
          this.auth?.ID
      );

      if (this.auth?.ID) {
        window.BX24.callMethod(
            "disk.storage.getlist",
            {
              filter: {
                'ENTITY_ID': this.auth?.ID,
                'ENTITY_TYPE': 'user'
              }
            }, (result) => {
              if (result.error()) {
                console.error(result.error());
              } else {
                setTimeout(() => {
                  this.fetchChildrenStorage(result.data()[0])
                }, 300)
              }
            }
        );
      }
    },

    loadChildrenStorage(storage) {
      this.isLoading = true

      window.BX24.callMethod(
          "disk.storage.getchildren",
          {
            id: storage.ID
          }, (result) => {
            if (result.error()) {
              console.error(result.error());
            } else {
              this.folders = result.data().map(child => ({...child, IS_CHECKED: false}));
              this.isLoading = false
            }
          }
      );
    },

    loadFolders({...folder}) {
      this.isLoading = true

      window.BX24.callMethod(
          "disk.folder.getchildren",
          {
            id: folder.ID
          },(result) => {
            if (result.error()){
              console.error(result.error());
            }else{
              this.folders = result.data().map(child => ({...child, IS_CHECKED: false}));
              this.buildTraceDocuments(folder)
              this.isLoading = false
            }
          }
      );
    },

    buildTraceDocuments({...folder}, isRoot = false) {
      if (isRoot) {
        this.navigations = [];
      }

      const nav = {...folder, INDEX: this.navigations.length};

      console.log(
          'Original Navigations',
          this.navigations
      )

      console.log(
          'NAV antes del find',
          nav,
          nav.ID
      )

      const findNav = this.navigations.find(item => item.ID === nav.ID)?.ID

      console.log(
          'findNav',
          findNav
      )

      if (!findNav) {
        this.navigations.push(nav)
      }

      console.log(
          'Final Navigations',
          this.navigations
      )
    },

    findFolder(item) {
      if (item.ID === null) {
        this.fetchStorageList()
        this.buildTraceDocuments({...this.navigations[0]}, true)
      } else {
        const temp = [];

        for (let i = 0; i <= item.INDEX; i++) {
          temp.push({... this.navigations.find(nav => nav.INDEX === i) });
        }

        this.fetchFolders(
            this.navigations.find(folder => folder.ID === item.ID)
        )

        this.navigations = temp
      }
    },


    async loadDemoUserAuth(){
      this.auth = {
        ACTIVE: true,
        DATE_REGISTER: "2022-03-16T00:00:00+00:00",
        ID: "467",
        IS_ONLINE: "Y",
        LAST_ACTIVITY_DATE: "2023-04-13 22:44:03",
        NAME: "ItechCrew",
        PERSONAL_BIRTHDAY: "",
        PERSONAL_COUNTRY: "0",
        TIMESTAMP_X: "19/11/2022 11:31:36",
        TIME_ZONE_OFFSET: "-21600",
        UF_DEPARTMENT: [208],
        UF_EMPLOYMENT_DATE: "",
        WORK_COUNTRY: "0"
      };
    },
    loadDemoContacts() {
      this.searchListing = demoContacts;
    },
    loadDemoCompanies() {
      this.searchListing = [
        {
          CURRENCY_ID: null,
          ID: "16685",
          REVENUE: null,
          TITLE: "GRUPO CONSTRUCTOR AMMA, S.A.",
          NAME: "GRUPO CONSTRUCTOR AMMA, S.A."
        },
        {
          CURRENCY_ID: null,
          ID: "16686",
          REVENUE: null,
          TITLE: "INDUSTRIAL MINERA COMERCIAL, Y/O FUNDIMART,S.A,",
          NAME: "INDUSTRIAL MINERA COMERCIAL, Y/O FUNDIMART,S.A,"
        },
        {
          CURRENCY_ID: null,
          ID: "16736",
          REVENUE: null,
          TITLE: "Consorcio Constructor Roca SA de CV",
          NAME: "Consorcio Constructor Roca SA de CV"
        }
      ];
    },
    loadDemoCompany() {
      this.companySelected = {
        CURRENCY_ID: null,
        ID: "16686",
        REVENUE: null,
        TITLE: "INDUSTRIAL MINERA COMERCIAL, Y/O FUNDIMART,S.A,"
      };

      this.companyName = this.companySelected?.TITLE;
    },
    loadDemoContactsByCompany() {
      this.contactsByCompany = demoContacts;
    },
    loadDemoStorage() {
      this.isLoading = true

      const storage = [
        {
          CODE: null,
          ENTITY_ID: "467",
          ENTITY_TYPE: "user",
          ID: "486",
          MODULE_ID: "disk",
          NAME: "ItechCrew",
          ROOT_OBJECT_ID: "3687"
        }
      ];

      this.fetchChildrenStorage(storage[0])
      this.isLoading = false
    },
    loadDemoChildrenStorage(storage) {

      this.isLoading = true

      const children = [
        {
          "ID":"3705",
          "NAME":"Documentos",
          "CODE":null,
          "STORAGE_ID":"486",
          "TYPE":"folder",
          "REAL_OBJECT_ID":"2393",
          "PARENT_ID":"3687",
          "DELETED_TYPE":"0",
          "CREATE_TIME":"2022-03-16T18:46:57+00:00",
          "UPDATE_TIME":"2022-03-16T18:46:57+00:00",
          "DELETE_TIME":null,
          "CREATED_BY":"11",
          "UPDATED_BY":"11",
          "DELETED_BY":"0",
          "DETAIL_URL":"https://dev-sim.maqro.com.mx:45211/company/personal/user/467/disk/path/Documentos"
        },
        {
          "ID":"3707",
          "NAME":"Archivos cargados",
          "CODE":"FOR_UPLOADED_FILES",
          "STORAGE_ID":"486",
          "TYPE":"folder",
          "REAL_OBJECT_ID":"3707",
          "PARENT_ID":"3687",
          "DELETED_TYPE":"0",
          "CREATE_TIME":"2022-03-16T18:56:31+00:00",
          "UPDATE_TIME":"2022-04-11T18:06:57+00:00",
          "DELETE_TIME":null,
          "CREATED_BY":"467",
          "UPDATED_BY":"467",
          "DELETED_BY":"0",
          "DETAIL_URL":"https://dev-sim.maqro.com.mx:45211/company/personal/user/467/disk/path/Archivos cargados"
        },
        {
          "ID":"3916",
          "NAME":"Archivos almacenados",
          "CODE":"FOR_SAVED_FILES",
          "STORAGE_ID":"486",
          "TYPE":"folder",
          "REAL_OBJECT_ID":"3916",
          "PARENT_ID":"3687",
          "DELETED_TYPE":"0",
          "CREATE_TIME":"2022-03-22T13:05:17+00:00",
          "UPDATE_TIME":"2022-03-28T17:40:17+00:00",
          "DELETE_TIME":null,
          "CREATED_BY":"467",
          "UPDATED_BY":"467",
          "DELETED_BY":"0",
          "DETAIL_URL":"https://dev-sim.maqro.com.mx:45211/company/personal/user/467/disk/path/Archivos almacenados"
        },
        {
          "ID":"4134",
          "NAME":"Proyecto y",
          "CODE":null,
          "STORAGE_ID":"486",
          "TYPE":"folder",
          "REAL_OBJECT_ID":"4133",
          "PARENT_ID":"3687",
          "DELETED_TYPE":"0",
          "CREATE_TIME":"2022-03-24T17:21:25+00:00",
          "UPDATE_TIME":"2022-03-24T17:21:25+00:00",
          "DELETE_TIME":null,
          "CREATED_BY":"467",
          "UPDATED_BY":"467",
          "DELETED_BY":"0",
          "DETAIL_URL":"https://dev-sim.maqro.com.mx:45211/company/personal/user/467/disk/path/Proyecto y"
        },
        {
          "ID":"4904",
          "NAME":"EDGAR HDZ",
          "CODE":null,
          "STORAGE_ID":"486",
          "TYPE":"folder",
          "REAL_OBJECT_ID":"4904",
          "PARENT_ID":"3687",
          "DELETED_TYPE":"0",
          "CREATE_TIME":"2022-03-30T09:11:03+00:00",
          "UPDATE_TIME":"2022-03-30T09:11:39+00:00",
          "DELETE_TIME":null,
          "CREATED_BY":"467",
          "UPDATED_BY":"467",
          "DELETED_BY":"0",
          "DETAIL_URL":"https://dev-sim.maqro.com.mx:45211/company/personal/user/467/disk/path/EDGAR HDZ"
        },
        {
          "ID":"5348",
          "NAME":"Mis Documentos",
          "CODE":null,
          "STORAGE_ID":"486",
          "TYPE":"folder",
          "REAL_OBJECT_ID":"5348",
          "PARENT_ID":"3687",
          "DELETED_TYPE":"0",
          "CREATE_TIME":"2022-03-30T16:35:50+00:00",
          "UPDATE_TIME":"2022-11-18T23:43:52+00:00",
          "DELETE_TIME":null,
          "CREATED_BY":"467",
          "UPDATED_BY":"467",
          "DELETED_BY":"0",
          "DETAIL_URL":"https://dev-sim.maqro.com.mx:45211/company/personal/user/467/disk/path/Mis Documentos"
        },
        {
          "ID":"4899",
          "NAME":"New spreadsheet.xlsx",
          "CODE":null,
          "STORAGE_ID":"486",
          "TYPE":"file",
          "PARENT_ID":"3687",
          "DELETED_TYPE":"0",
          "GLOBAL_CONTENT_VERSION":"1",
          "FILE_ID":"5818",
          "SIZE":"8858",
          "CREATE_TIME":"2022-03-30T08:46:10+00:00",
          "UPDATE_TIME":"2022-03-30T08:46:10+00:00",
          "DELETE_TIME":null,
          "CREATED_BY":"467",
          "UPDATED_BY":"467",
          "DELETED_BY":"0",
          "DOWNLOAD_URL":"https://dev-sim.maqro.com.mx:45211/rest/download.json?auth=2e740764005e3b7b00573b87000001d3a0ab07d978a3a91f22b8b37c886220a5274e66&token=disk%7CaWQ9NDg5OSZfPXc1R3ptdnc3M1lnbWRMR1doRkhiMzFMdEVpMHZ3UWFN%7CImRvd25sb2FkfGRpc2t8YVdROU5EZzVPU1pmUFhjMVIzcHRkbmMzTTFsbmJXUk1SMWRvUmtoaU16Rk1kRVZwTUhaM1VXRk58MmU3NDA3NjQwMDVlM2I3YjAwNTczYjg3MDAwMDAxZDNhMGFiMDdkOTc4YTNhOTFmMjJiOGIzN2M4ODYyMjBhNTI3NGU2NiI%3D.E8dLLdK8k131WAV%2B3y06A1pGFL4mamDZt1a5BViqbSM%3D",
          "DETAIL_URL":"https://dev-sim.maqro.com.mx:45211/company/personal/user/467/disk/file/New spreadsheet.xlsx"
        }
      ]

      this.folders = children.filter(child => child.STORAGE_ID === storage.ID)
          .map(child => ({...child, IS_CHECKED: false}));

      this.isLoading = false
    },
    loadDemoFolders(folder) {
      this.isLoading = true

      this.folders = (this.loadDemoFoldersFilter(folder) || [])
          .map(child => ({...child, IS_CHECKED: false}));

      this.buildTraceDocuments(folder)

      this.isLoading = false
    },

    loadDemoFoldersFilter(folder) {

      this.isLoading = true

      if (folder.ID === '3705') {
        this.isLoading = false
        return [
          {
            "ID": "3707",
            "NAME": "Archivos cargados",
            "CODE": "FOR_UPLOADED_FILES",
            "STORAGE_ID": "486",
            "TYPE": "folder",
            "REAL_OBJECT_ID": "3707",
            "PARENT_ID": "3687",
            "DELETED_TYPE": "0",
            "CREATE_TIME": "2022-03-16T18:56:31+00:00",
            "UPDATE_TIME": "2022-04-11T18:06:57+00:00",
            "DELETE_TIME": null,
            "CREATED_BY": "467",
            "UPDATED_BY": "467",
            "DELETED_BY": "0",
            "DETAIL_URL": "https://dev-sim.maqro.com.mx:45211/company/personal/user/467/disk/path/Archivos cargados"
          },
          {
            "ID": "3708",
            "NAME": "Promesa de Venta",
            "CODE": "FOR_UPLOADED_FILES",
            "STORAGE_ID": "486",
            "TYPE": "folder",
            "REAL_OBJECT_ID": "3708",
            "PARENT_ID": "3687",
            "DELETED_TYPE": "0",
            "CREATE_TIME": "2022-03-16T18:56:31+00:00",
            "UPDATE_TIME": "2022-04-11T18:06:57+00:00",
            "DELETE_TIME": null,
            "CREATED_BY": "467",
            "UPDATED_BY": "467",
            "DELETED_BY": "0",
            "DETAIL_URL": "https://dev-sim.maqro.com.mx:45211/company/personal/user/467/disk/path/Archivos cargados"
          }
        ]
      }

      if (folder.ID === '3707') {
        this.isLoading = false
        return [
          {
            "ID":"49001",
            "NAME":"New spreadsheet.xlsx",
            "CODE":null,
            "STORAGE_ID":"486",
            "TYPE":"file",
            "PARENT_ID":"3687",
            "DELETED_TYPE":"0",
            "GLOBAL_CONTENT_VERSION":"1",
            "FILE_ID":"5818",
            "SIZE":"8858",
            "CREATE_TIME":"2022-03-30T08:46:10+00:00",
            "UPDATE_TIME":"2022-03-30T08:46:10+00:00",
            "DELETE_TIME":null,
            "CREATED_BY":"467",
            "UPDATED_BY":"467",
            "DELETED_BY":"0",
            "DOWNLOAD_URL":"https://dev-sim.maqro.com.mx:45211/rest/download.json?auth=2e740764005e3b7b00573b87000001d3a0ab07d978a3a91f22b8b37c886220a5274e66&token=disk%7CaWQ9NDg5OSZfPXc1R3ptdnc3M1lnbWRMR1doRkhiMzFMdEVpMHZ3UWFN%7CImRvd25sb2FkfGRpc2t8YVdROU5EZzVPU1pmUFhjMVIzcHRkbmMzTTFsbmJXUk1SMWRvUmtoaU16Rk1kRVZwTUhaM1VXRk58MmU3NDA3NjQwMDVlM2I3YjAwNTczYjg3MDAwMDAxZDNhMGFiMDdkOTc4YTNhOTFmMjJiOGIzN2M4ODYyMjBhNTI3NGU2NiI%3D.E8dLLdK8k131WAV%2B3y06A1pGFL4mamDZt1a5BViqbSM%3D",
            "DETAIL_URL":"https://dev-sim.maqro.com.mx:45211/company/personal/user/467/disk/file/New spreadsheet.xlsx"
          },
          {
            "ID":"49002",
            "NAME":"informe.doc",
            "CODE":null,
            "STORAGE_ID":"486",
            "TYPE":"file",
            "PARENT_ID":"3687",
            "DELETED_TYPE":"0",
            "GLOBAL_CONTENT_VERSION":"1",
            "FILE_ID":"5818",
            "SIZE":"8858",
            "CREATE_TIME":"2022-03-30T08:46:10+00:00",
            "UPDATE_TIME":"2022-03-30T08:46:10+00:00",
            "DELETE_TIME":null,
            "CREATED_BY":"467",
            "UPDATED_BY":"467",
            "DELETED_BY":"0",
            "DOWNLOAD_URL":"https://dev-sim.maqro.com.mx:45211/rest/download.json?auth=2e740764005e3b7b00573b87000001d3a0ab07d978a3a91f22b8b37c886220a5274e66&token=disk%7CaWQ9NDg5OSZfPXc1R3ptdnc3M1lnbWRMR1doRkhiMzFMdEVpMHZ3UWFN%7CImRvd25sb2FkfGRpc2t8YVdROU5EZzVPU1pmUFhjMVIzcHRkbmMzTTFsbmJXUk1SMWRvUmtoaU16Rk1kRVZwTUhaM1VXRk58MmU3NDA3NjQwMDVlM2I3YjAwNTczYjg3MDAwMDAxZDNhMGFiMDdkOTc4YTNhOTFmMjJiOGIzN2M4ODYyMjBhNTI3NGU2NiI%3D.E8dLLdK8k131WAV%2B3y06A1pGFL4mamDZt1a5BViqbSM%3D",
            "DETAIL_URL":"https://dev-sim.maqro.com.mx:45211/company/personal/user/467/disk/file/New spreadsheet.xlsx"
          }
        ]
      }
    },

    resetInput() {
      this.selectedSearch = null;
      this.contactId = null;
      this.contactName = null;
      this.contactEmail = null;
      this.companyName = null;
    },

    getIcon(folder) {
      if (folder.type === 'folder') {
        return 'ri-folder-2-fill';
      }

      const name = folder.NAME.split('.');
      const extension = name[name.length - 1];

      return icons[extension.toLowerCase()] ?? 'ri-folder-2-fill';
    },

    check({...option}, event) {
      const {checked} = event.target

      if (checked) {
        console.info('File to send:', option)
        this.files.push(option)
      } else {
        this.files.splice(this.files.findIndex(file => file.ID === option.ID), 1);
      }
    },

    setPages() {
      let numberOfPages = Math.ceil(this.folders.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },

    async sendEmail() {

      console.log(
          '================================================'
      )

      console.log(
          'Start Send Email....'
      )

      try {

        if (!this.contactEmail) {
          Swal.fire({
            title: "Advertencia",
            text: "Debes seleccionar/escribir un correo electrónico para enviar la información",
            icon: "warning",
            confirmButtonClass: "btn btn-warning w-xs mt-2",
            buttonsStyling: false,
            showCloseButton: true,
            confirmButtonText: "Aceptar"
          });

          return;
        }

        const data = {
          from_name: 'MAQRO',
          to: this.contactEmail,
          subject: 'Envío de documentos',
          message: "Te envío la información acordada cualquier cosa estoy al pendiente",
          url_files: this.files.map(file => {
            return {
              url: file.DOWNLOAD_URL,
              name: file.NAME
            }
          }),
          user_name: this.auth.NAME,
          contact_name: this.contactName ?? 'Contacto',
        };

        console.info('URL SEND EMAIL:', process.env.VUE_APP_API_SMSBOX);

        const response = await axios.post(`${process.env.VUE_APP_API_SMSBOX}/api/email/queued`, data);

        console.log(
            'response',
            response.data
        )

       await this.activityAdd();

        Swal.fire({
          title: 'Enviado',
          text: response.data.message,
          icon: "success",
          confirmButtonClass: "btn btn-warning w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
          confirmButtonText: "Aceptar"
        });

      } catch (e) {
        console.log(e.response);

        Swal.fire({
          title: e.response.data.status,
          text: e.response.data.message,
          icon: "error",
          confirmButtonClass: "btn btn-danger w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
          confirmButtonText: "Aceptar"
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <b-row class="justify-content-xl-center">
      <b-col xl="7">
        <b-card no-body>
          <b-card-body class="checkout-tab">
            <b-form action="#">
              <div class="step-arrow-nav mt-n3 mx-n3 mb-3">
                <ul class="nav nav-pills nav-justified custom-nav" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link fs-15 p-3 active" id="pills-client-info-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-client-info" type="button" role="tab"
                            aria-controls="pills-client-info"
                            aria-selected="true">
                      <i class="
                          ri-user-2-line
                          fs-16
                          avatar-xs d-inline-flex align-items-center justify-content-center
                          bg-soft-dark
                          text-yellow-c
                          rounded-circle
                          align-middle
                          me-2
                        "></i>
                      Cliente
                    </button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button class="nav-link fs-15 p-3" id="pills-prospect-info-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-prospect-info" type="button" role="tab"
                            aria-controls="pills-prospect-info"
                            aria-selected="false">
                      <i class="
                          ri-truck-line
                          fs-16
                          avatar-xs d-inline-flex align-items-center justify-content-center
                          bg-soft-dark
                          text-yellow-c
                          rounded-circle
                          align-middle
                          me-2
                        "></i>
                      Prospecto
                    </button>
                  </li>
                </ul>
              </div>

              <div class="tab-content">

                <div class="tab-pane fade show active" id="pills-client-info" role="tabpanel"
                     aria-labelledby="pills-client-info-tab">
                  <div>
                    <h5 class="mb-1">Información</h5>
                    <p class="text-muted mb-4">
                      Ingresa la información del cliente para enviarle la información
                    </p>
                  </div>

                  <div>
                    <b-row>
                      <b-col sm="6">
                        <div class="mb-3">
                          <Multiselect
                              class="form-control"
                              v-model="selectedSearch"
                              :close-on-select="true"
                              :searchable="true"
                              label="NAME"
                              valueProp="ID"
                              :options="searchListing"
                              :object="true"
                              @select="onChangeSearch"
                          />

                        </div>
                      </b-col>

                      <b-col sm="6">
                        <div class="mb-3">
                          <Multiselect
                              class="form-control"
                              v-model="selectedFilter"
                              :close-on-select="true"
                              :searchable="true"
                              label="name"
                              valueProp="id"
                              :options="loadInformationList"
                              :object="true"
                              @select="onChangeFilter"
                          />
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="12">
                        <div class="mb-3">
                          <label for="country" class="form-label" v-if="selectedFilter?.id === 1">Contacto</label>
                          <label for="country" class="form-label" v-else>Contactos</label>

                          <input
                              type="text"
                              class="form-control"
                              v-model="contactName"
                              placeholder="Contacto"
                              v-if="selectedFilter?.id === 1"
                          />

                          <Multiselect
                              v-else
                              class="form-control"
                              v-model="selectedContactByCompany"
                              :close-on-select="true"
                              :searchable="true"
                              label="NAME"
                              valueProp="ID"
                              :options="contactsByCompany"
                              :object="true"
                              @select="onChangeContactByCompany"
                          />

                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="12">
                        <div class="mb-3">
                          <label for="country" class="form-label">Correo Electrónico</label>
                          <div class="input-group">
                            <span class="input-group-text" id="email">@</span>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Correo Electrónico"
                                aria-label="email"
                                aria-describedby="basic-addon1"
                                v-model="contactEmail"
                            >
                          </div>
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="12">
                        <div class="mb-3">
                          <label for="country" class="form-label">Compañía</label>
                          <input
                              type="text"
                              class="form-control"
                              id="company"
                              v-model="companyName"
                              placeholder="Compañía"
                          />
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="12">
                        <div class="mb-3">
                          <label for="country" class="form-label">Marca</label>
                          <select class="form-select" id="brand" data-plugin="choices">
                            <option
                                v-for="(item, key) in loadBrandList"
                                :key="key"
                                :value="item.name"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </b-col>
                    </b-row>


                    <b-row>
                      <b-col md="12">
                        <div class="table-responsive">

                          <div
                              class="d-sm-flex align-items-center justify-content-between my-2"
                              v-if="!isLoading"
                          >
                            <div class="page-title-right">
                              <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item" v-for="(item, index) in navigations" :key="index" :class="{ active: item[0] }">
                                  <b-link
                                      @click.prevent="findFolder(item)">
                                    {{ item.NAME }}
                                  </b-link>
                                </li>
                              </ol>
                            </div>
                          </div>

                          <content-loader
                              v-if="isLoading"
                              :speed="2"
                              :animate="true"
                          >
                          </content-loader>

                          <table class="table align-middle table-nowrap mb-0" v-if="!isLoading">
                            <thead class="table-active">
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Nombre</th>
                              <th scope="col" class="text-center">Acciones</th>
                            </tr>
                            </thead>

                            <tbody id="file-list">
                              <tr v-for="(folder, index) of resultQuery" :key="index">

                              <td>
                                <div
                                    class="form-check form-check-danger mb-3 fs-15 flex-grow-1"
                                    v-if="folder.TYPE === 'file'"
                                >
                                  <input
                                      class="form-check-input"
                                      type="checkbox"
                                      :checked="files.includes(folder.ID)"
                                      @change="check(folder, $event)"
                                  />
                                </div>
                              </td>

                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="flex-shrink-0 fs-17 me-2 filelist-icon">
                                    <i :class="getIcon(folder) + ' align-bottom display-7 ' + (folder.TYPE === 'folder' ? 'text-warning' : 'text-success')"></i>
                                  </div>


                                  <div class="flex-grow-1 filelist-name cursor-pointer">
                                    <span v-if="folder.TYPE === 'folder'" @click="fetchFolders(folder)">
                                      {{ folder.NAME }}
                                    </span>

                                    <span v-else>
                                      {{ folder.NAME }}
                                    </span>
                                  </div>

                                  <div class="d-none filelist-type">
                                    {{ folder.TYPE }}
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div class="d-flex gap-3 justify-content-center">
                                  <div class="dropdown">
                                    <b-button size="sm" variant="light" class="btn-icon dropdown" type="button" data-bs-toggle="dropdown"
                                              aria-expanded="false">
                                      <i class="ri-more-fill align-bottom"></i>
                                    </b-button>
                                    <ul class="dropdown-menu dropdown-menu-end">
                                      <li>
                                        <b-link class="dropdown-item viewfile-list" href="#">Ver</b-link>
                                      </li>
                                      <li class="dropdown-divider"></li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>

                        </div>

                        <div
                            class="d-flex justify-content-end mt-3"
                            v-if="!isLoading"
                        >
                          <div class="pagination-wrap hstack gap-2">
                            <b-link
                                class="page-item pagination-prev disabled"
                                href="#"
                                v-if="page !== 1"
                                @click="page--"
                            >
                              <i class="mdi mdi-chevron-left"></i>
                            </b-link>

                            <ul class="pagination pagination-separated listjs-pagination justify-content-center justify-content-sm-end mb-0">
                              <li
                                  class="page-item"
                                  :class="{
                                        active: pageNumber === page,
                                        disabled: pageNumber === '...',
                                      }"
                                  v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                                  :key="index"
                                  @click="page = pageNumber"
                              >
                                <b-link class="page-link" href="#">{{ pageNumber }}</b-link>
                              </li>
                            </ul>

                            <b-link
                                class="page-item pagination-next"
                                href="#"
                                @click="page++"
                                v-if="page < pages.length"
                            >
                              <i class="mdi mdi-chevron-right"></i>
                            </b-link>

                          </div>
                        </div>

                      </b-col>

                    </b-row>

                    <b-row v-if="files.length >= 1">

                      <div class="hstack mt-4 text-muted">
                        <span class="me-auto">
                          Archivos a enviar
                        </span>
                      </div>

                      <b-col v-for="(file, index) of files" :key="index" cols="2" xxl="2" class=" folder-card my-4">

                        <b-card no-body class="bg-light shadow-none">
                          <b-card-body>
                            <div class="d-flex mb-1">
                            </div>

                            <div class="text-center">
                              <div class="mb-2">
                                <i :class="getIcon(file) + ' align-bottom display-7 ' + (file.TYPE === 'folder' ? 'text-warning' : 'text-success')"></i>
                              </div>

                              <h6 class="fs-6 folder-name">{{ file.NAME }}</h6>
                            </div>
                          </b-card-body>
                        </b-card>
                      </b-col>
                    </b-row>


                    <div class="d-flex align-items-start gap-3 mt-3 justify-content-center">
                      <b-button variant="light" type="button" class="btn-label right"
                                data-nexttab="pills-bill-address-tab">
                        <i class="
                            ri-close-line
                            label-icon
                            align-middle
                            fs-16
                            ms-2
                          "></i>
                        CANCELAR
                      </b-button>

                      <b-button
                          variant="custom-yellow"
                          type="button"
                          class="btn-label right"
                          data-nexttab="pills-bill-address-tab"
                          @click.prevent="sendEmail"
                      >
                        <i class="
                            ri-send-plane-fill
                            label-icon
                            align-middle
                            fs-16
                            ms-2
                          "></i>
                        ENVIAR
                      </b-button>
                    </div>
                  </div>
                </div>


                <div class="tab-pane fade" id="pills-prospect-info" role="tabpanel"
                     aria-labelledby="pills-prospect-info-tab">
                  <div class="text-center py-5">

                    <h5>Pending... !</h5>
                    <p class="text-muted">
                      Waiting design...
                    </p>

                    <h3 class="fw-semibold">
                      Prospecto....
                    </h3>
                  </div>
                </div>
              </div>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>


    </b-row>
  </Layout>
</template>
